<template>

  <div class="invoice-box">
    <table
      cellpadding="0"
      cellspacing="0"
    >
      <tr class="top">
        <td
          colspan="4"
          :style="{'border-bottom':'solid 2px '+invoiceData.design.themeColor}"
        >
          <table>
            <tr>
              <td
                class="title"
                style="text-align: center; vertical-align: top; width: 33%; padding-top: 0;"
              >

                <h2>
                  {{ invoiceData.content.company_info.name }}
                </h2>
                <img
                  v-if="invoiceData.design.logo.file"
                  :src="invoiceData.design.logo.file.url"
                  :style="'width: 100%; margin-left:'+invoiceData.design.logo.align+'mm; max-width: '+(invoiceData.design.logo.size=='sm'?'100px':invoiceData.design.logo.size=='md'?'200px':'300px')"
                >
              </td>
              <td style="width: 33%;" />

              <td style="text-align: left; vertical-align: top; width: 33%; padding-top: 0;">
                <h2>Invoice</h2>

                <span class="inline-span"><strong>{{ invoiceData.content.company_info.name }}</strong></span>
                <p
                  class="margin-bottom-0"
                  style="display:inline-block; max-width:250px;"
                >
                  {{ invoiceData.content.company_info.address }}
                </p>
                <span class="inline-span">{{ invoiceData.content.company_info.email }}</span>
                <span class="inline-span">{{ invoiceData.content.company_info.website }}</span>
                <span class="inline-span">{{ invoiceData.content.company_info.phone }}</span>
                <p>
                  {{ invoiceData.content.company_info.additional_information }}
                </p>
                <span
                  v-if="invoiceData.content.company_info.gst"
                  class="inline-span"
                >
                  GST: {{ invoiceData.content.company_info.gst }}
                </span>
                <span
                  v-if="invoiceData.content.company_info.wbc"
                  class="inline-span"
                >
                  WCB: {{ invoiceData.content.company_info.wbc }}
                </span>
              </td>
            </tr>
          </table>
        </td>
      </tr>

      <tr class="information">
        <td colspan="4">
          <table>
            <tr>
              <td>

                <table>
                  <tr>
                    <td>
                      <strong>  {{ labels.document_headings.filter(a=>a.value=='bill_to')[0].input }}:</strong>
                    </td>
                    <td
                      v-if="invoiceData.content.billing"
                      style="text-align: left;"
                    >

                      {{ invoiceData.content.billing.name }}<br>
                      <span
                        v-if="invoiceData.content.billing.email"
                        class="inline-span"
                      >{{ invoiceData.content.billing.email }} </span>
                      <p
                        style="max-width: 250px; word-wrap: break-word; white-space: normal;
                        overflow: hidden; text-overflow: ellipsis;"
                        class="margin-bottom-0"
                      >
                        {{ invoiceData.content.billing.address }}
                      </p>
                      <span
                        v-if="invoiceData.content.billing.phone"
                        class="inline-span"
                      >{{ invoiceData.content.billing.phone }}</span>
                      <span class="inline-span">{{ invoiceData.content.billing.mobile }}</span>
                    </td>
                  </tr>
                </table>

              </td>
              <td>

                <strong> {{ labels.document_headings.filter(a=>a.value=='invoice_no')[0].input }}</strong>  #:{{ invoiceData.design.options.numbers.invoiceNumberPrefix }}{{ invoiceData.content.invoice_number }}<br>
                <strong>  {{ labels.document_headings.filter(a=>a.value=='invoice_date')[0].input }}:</strong> {{ invoiceData.content.invoice_date }}<br>
                <span v-if="invoiceData.design.options.header.showDueDate"> <strong>{{ labels.document_headings.filter(a=>a.value=='due_date')[0].input }}:</strong> {{ invoiceData.content.due_date }}</span>

              </td>

            </tr>
          </table>
          <table style="width:50%">
            <tr
              v-for="field,index in invoiceData.design.options.header.customFields"
              :key="index"
            >
              <td>
                <span><strong>{{ field.name }}</strong></span>
              </td>
              <td style="text-align:left;">
                {{ field.value }}
              </td>
            </tr>
          </table>
        </td>
      </tr>

    </table>
    <table>
      <tr
        class="heading"
        :style="{'border-bottom':'solid 2px '+invoiceData.design.themeColor}"
      >
        <td v-if="invoiceData.design.options.table.showItemCode">
          {{ labels.general.filter(a=>a.value=='code')[0].input }}
        </td>
        <td :style="{'width':'400px','text-align':'left'}">
          {{ labels.general.filter(a=>a.value=='description')[0].input }}
        </td>
        <td
          v-if="invoiceData.design.options.table.showQuantityAndRate"
          :style="{'text-align':'center'}"
        >
          {{ labels.general.filter(a=>a.value=='quantity')[0].input }}
        </td>
        <td
          v-if="invoiceData.design.options.table.showQuantityAndRate"
          :style="{'text-align':'center'}"
        >
          {{ labels.general.filter(a=>a.value=='unit_price')[0].input }}
        </td>
        <td
          v-for="singleTax,index in invoiceData.content.totals.taxes"
          :key="index"

          :style="{'text-align':'center'}"
        >
          <span v-if="invoiceData.design.options.table.showTax"> {{ singleTax.tax_name }}</span>
        </td>
        <td :style="{'text-align':'right'}">
          {{ labels.general.filter(a=>a.value=='amount')[0].input }}
        </td>
      </tr>

      <tr
        v-for="item,index in invoiceData.content.items"
        :key="index"
        class="item"
      >
        <td v-if="invoiceData.design.options.table.showItemCode">
          {{ item.code }}
        </td>
        <td :style="{'text-align':'left'}">
          <p> {{ item.name }}</p>
          <p v-if="item.description">
            {{ item.description }}
          </p>
        </td>

        <td
          v-if="invoiceData.design.options.table.showQuantityAndRate"
          :style="{'text-align':'center'}"
        >
          {{ item.quantity }}
        </td>
        <td
          v-if="invoiceData.design.options.table.showQuantityAndRate"
          :style="{'text-align':'center'}"
        >
          {{ invoiceData.content.currency_symbol }}  {{ $staticParams.formatCurrency(item.unit_price) }}
        </td>
        <td
          v-for="singleTax,taxIndex in invoiceData.content.totals.taxes"

          :key="taxIndex"
          :style="{'text-align':'center'}"
        >
          <span v-if="invoiceData.design.options.table.showTax"> {{ getItemTaxRate(item,singleTax) }}%</span>

        </td>
        <td :style="{'text-align':'right'}">
          {{ invoiceData.content.currency_symbol }} {{ $staticParams.formatCurrency(item.amount) }}
        </td>
      </tr>

    </table>
    <table>
      <tr>

        <td style="width:55%" />
        <td>
          <table>
            <tr> <td :style="{'text-align':'right'}">
                   Subtotal
                 </td>
              <td :style="{'text-align':'right'}">
                {{ invoiceData.content.currency_symbol }}  {{ $staticParams.formatCurrency(invoiceData.content.totals.subtotal) }}
              </td>
            </tr>
            <tr v-if="invoiceData.content.totals.discount>0">
              <td :style="{'text-align':'right'}">
                Discount
              </td>
              <td :style="{'text-align':'right'}">
                {{ invoiceData.content.currency_symbol }}  {{ $staticParams.formatCurrency(invoiceData.content.totals.discount) }}
              </td>
            </tr>
            <tr
              v-for="singleTax,taxIndex in invoiceData.content.totals.taxes"
              :key="taxIndex"
            >
              <td :style="{'text-align':'right'}">
                {{ singleTax.tax_name }} {{ singleTax.tax_rate }}%
              </td>
              <td :style="{'text-align':'right'}">
                {{ invoiceData.content.currency_symbol }} {{ $staticParams.formatCurrency(singleTax.amount) }}
              </td>
            </tr>
            <tr> <td :style="{'text-align':'right'}">
                   {{ labels.document_summary.filter(a=>a.value=='total')[0].input }}
                 </td>
              <td :style="{'text-align':'right'}">
                {{ invoiceData.content.currency_symbol }}  {{ $staticParams.formatCurrency(invoiceData.content.totals.total) }}
              </td>
            </tr>
            <br>
            <tr :style="{'font-weight':'bold','font-size':'24px','border-style':'solid','border-width':'2px 0','border-color':invoiceData.design.themeColor}">
              <td :style="{'text-align':'right'}">
                {{ labels.document_summary.filter(a=>a.value=='total')[0].input }}
              </td>
              <td :style="{'text-align':'right'}">
                {{ invoiceData.content.currency_symbol }}  {{ $staticParams.formatCurrency(invoiceData.content.totals.total) }}
              </td>
            </tr>
          </table>
        </td>
      </tr>
    </table>
    <table>
      <tr v-if="invoiceData.design.options.footer.comments.genericComment">
        <td>
          <h5 style="font-weight:bold;">
            {{ labels.document_summary.filter(a=>a.value=='notes')[0].input }}
          </h5>
          <p>{{ invoiceData.design.options.footer.comments.genericComment }}</p>
        </td>
      </tr>
      <tr v-if="invoiceData.design.options.footer.comments.termsAndConditions">
        <td>
          <h5 style="font-weight:bold;">
            {{ labels.document_summary.filter(a=>a.value=='terms_and_condictions')[0].input }}
          </h5>
          <p>{{ invoiceData.design.options.footer.comments.termsAndConditions }}</p>
        </td>
      </tr>
    </table>
    <table
      v-if="invoiceData.design.options.footer.showSignatureLine"
      style="margin-top:150px;"
    >
      <tr>
        <td style="text-align:center; border-top: solid; width: 50%;">

          <h5 style="font-weight:bold; margin-top: 10px;">
            {{ labels.document_summary.filter(a=>a.value=='company_signature')[0].input }}
          </h5>
        </td>
        <td />
        <td style="text-align:center; border-top: solid; width: 50%;">
          <h5 style="font-weight:bold; margin-top: 10px;">
            {{ labels.document_summary.filter(a=>a.value=='client_signature')[0].input }}
          </h5>
        </td>
      </tr>

    </table>
  </div>

</template>

<script>

export default {
  components: {
  },
  props: {
    invoiceData: {
      type: Object,
      required: true,
    },

  },
  data() {
    return {

    }
  },
  computed: {
    labels() {
      return this.invoiceData.design.options.labels
    },
  },
  methods: {
    getItemTaxRate(item, taxObj) {
      const tax = item.applied_taxes.filter(f => f.tax_id === taxObj.tax_id)
      if (tax.length > 0) {
        return tax[0].tax_rate//
      }

      return 0
    },
  },
}
</script>
 <style scoped>
        .invoice-box {
       width:210mm;
           min-height: 11in;
        margin: auto;
        padding: 30px;
        border: 1px solid #eee;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
        font-size: 12px;
        line-height: 24px;
        font-family: 'Helvetica Neue', 'Helvetica', Helvetica, Arial, sans-serif;
        color: #555;
        }

        .invoice-box table {
        width: 100%;
        line-height: inherit;
        text-align: left;
        }

        .invoice-box table td {
        padding: 1px;
        vertical-align: top;
        }

        .invoice-box table tr td:nth-child(2) {
        text-align: right;
        }

        .invoice-box table tr.top table td {
        padding-bottom: 20px;
        }

        .invoice-box table tr.top table td.title {
        font-size: 45px;
        line-height: 45px;
        color: #333;
        }

        .invoice-box table tr.information table td {
        padding-bottom: 40px;
        }

        .invoice-box table tr.heading td {

        border-bottom: 1px solid #ddd;
        font-weight: bold;
        }

        .invoice-box table tr.details td {
        padding-bottom: 20px;
        }

        .invoice-box table tr.item td {
        border-bottom: 1px solid #eee;
        }

        .invoice-box table tr.item.last td {
        border-bottom: none;
        }

        .invoice-box table tr.total td:nth-child(2) {
        border-top: 2px solid #eee;
        font-weight: bold;
        }

        /** RTL **/
        .invoice-box.rtl {
        direction: rtl;
        font-family: Tahoma, 'Helvetica Neue', 'Helvetica', Helvetica, Arial, sans-serif;
        }

        .invoice-box.rtl table {
        text-align: right;
        }

        .invoice-box.rtl table tr td:nth-child(2) {
        text-align: left;
        }
        .inline-span{
          display: block;
        }
        .margin-bottom-0{
          margin-bottom:0 !important;
        }
      </style>
